export function getBarOptions(data, labels) {
    return {
        tooltip: {
            show: true
        },
        xAxis: {
            type: "category",
            data: labels
        },
        yAxis: {
            type: "value"
        },
        series: data,
        legend: {
            y: "bottom",
            show: true
        }
    };
}

export function getPieOptions(data) {
    return {
        tooltip: {
            show: true
        },
        series: {
            type: "pie",
            data: data,
            label: {
                position: "outer",
                alignTo: "none",
                bleedMargin: 5
            },
            top: "10%",
            bottom: "10%"
        },
        legend: {
            top: "bottom",
            show: true
        }
    };
}
