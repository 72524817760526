import React, { useEffect, useState } from "react";

import ECharts from "../components/Echarts/Echarts";
import "echarts-wordcloud";

import {
    getBarOptions,
    getPieOptions,
} from "../components/Echarts/ChartsOptions";

const initialBar = {
    data: [],
    error: undefined,
    loading: true,
    vertical: false
};

const initialPie = { data: [], error: undefined, loading: true };

export default function BarPieWordCloud() {
    const [barData, setBarData] = useState(initialBar);
    const [pieData, setPieData] = useState(initialPie);

    useEffect(() => {
        getBarChart(); // API
        getPieChart(); // API
    }, []);

    // to vertical the chart
    useEffect(() => {
        if (barData.data) {
            setBarData((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    xAxis: prev.data.yAxis,
                    yAxis: prev.data.xAxis
                }
            }));
        }
    }, [barData.vertical]);

    function getBarChart() {
        setBarData((prev) => ({ ...prev, loading: true }));
        // added timeout only to show loading for example, instead use api call
        setTimeout(() => {
            const labels = ["a", "b", "c"];
            const datasets = [
                {
                    name: "",
                    type: barData.vertical ? "bar" : "line",
                    smooth: true,
                    data: [10, 50, 20],
                    lineStyle: {color: '#fff'}
                },
                {
                    name: "",
                    type: barData.vertical ? "bar" : "line",
                    smooth: true,
                    data: [20, 10, 30],
                    lineStyle: {color: '#B87333'}
                }
            ];

            const barOptions = getBarOptions(datasets, labels);

            setBarData({
                data: barOptions,
                error: false,
                loading: false,
                vertical: false
            });
        }, 2000);
    }

    function getPieChart() {
        setPieData((prev) => ({ ...prev, loading: true }));
        // added timeout only to show loading for example, instead use api call
        setTimeout(() => {
            const pieOptions = getPieOptions([
                { name: "", value: 20, itemStyle: {color: '#B87333'}, },
                { name: "", value: 30, itemStyle: {color: '#fff'}, }
            ]);

            setPieData({
                data: pieOptions,
                error: false,
                loading: false
            });
        }, 2000);
    }

    return (
        <div className="chart-row w-full flex flex-col items-start px-5 h-full">
            <div className="bg-[#303030] rounded-lg shadow-xl w-full mb-4 h-[339px]">
                <ECharts
                    xLabel={barData.labels}
                    loading={barData.loading}
                    options={barData.data}
                />
            </div>
            <div className="bg-[#303030] rounded-lg shadow-xl w-full h-[339px]">
                <ECharts loading={pieData.loading} options={pieData.data} />
            </div>
        </div>
    );
}
