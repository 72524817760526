import React, { useState } from "react";
import robiq from "../assets/images/robiq-logo.svg";

const LoginPage = ({ onLogin }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const login = () => {
        const data = { email, password };
        if(data.email === process.env.REACT_APP_USERNAME && data.password === process.env.REACT_APP_PASSWORD) {
            onLogin(data);
        } else {
            alert('Please enter fields currently');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-[#202020] w-full">
            <div className="max-w-md w-full bg-[#303030] rounded-lg shadow-lg p-8">
                <img src={robiq} alt="logo" width={48} className="mx-auto mb-4" />
                <h2 className="text-2xl font-bold text-white text-center">Welcome to Robiq</h2>
                <form className="mt-6 space-y-4">
                    {/* Email Field */}
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-white">
                            Email Address
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="mt-1 block w-full px-4 py-2 border border-white-300 rounded-lg shadow-sm outline-none"
                            placeholder="you@example.com"
                        />
                    </div>

                    {/* Password Field */}
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-white">
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mt-1 block w-full px-4 py-2 border border-white-300 rounded-lg shadow-sm outline-none"
                            placeholder="Enter your password"
                        />
                    </div>

                    {/* Submit Button */}
                    <button
                        type="button"
                        className="w-full text-white py-2 px-4 rounded-lg bg-[#B87333]"
                        onClick={login}
                    >
                        Log In
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;