import React, { useState, useEffect, useRef, useCallback } from "react";
import AWS from "aws-sdk";
import {
  AWS_TOPIC,
  AWS_REGION,
  AWS_ACCESSKEYID,
  AWS_SECRETACCESSKEY,
} from "./aws/awsEnv";

import arrow from "./assets/images/arrow.svg";
import robiq from "./assets/images/robiq-logo.svg";

import BarPieWordCloud from "./pages/BarPie";
import WebRTCStream from "./components/Stream/WebRTCStream";
import LoginPage from "./pages/LoginPage";

function App() {
  const [direction, setDirection] = useState("");
  const [activeKeys, setActiveKeys] = useState({});
  const [speed, setSpeed] = useState(1);

  const speedRef = useRef(speed);

  const [isActivePage, setIsActivePage] = useState(false);
  const [userData, setUserData] = useState({ email: "", password: "" });

  useEffect(() => {
    speedRef.current = speed;
  }, [speed]);

  const calculateDirection = useCallback(() => {
    const { ArrowUp, ArrowDown, ArrowLeft, ArrowRight, w, a, s, d } =
      activeKeys;

    if ((ArrowUp || w) && (ArrowRight || d)) return "up-right";
    if ((ArrowUp || w) && (ArrowLeft || a)) return "up-left";
    if ((ArrowDown || s) && (ArrowRight || d)) return "down-right";
    if ((ArrowDown || s) && (ArrowLeft || a)) return "down-left";
    if (ArrowUp || w) return "up";
    if (ArrowDown || s) return "down";
    if (ArrowLeft || a) return "left";
    if (ArrowRight || d) return "right";
    return ""; // No direction
  }, [activeKeys]);

  const handleKeyDown = (e) => {
    if (e.repeat) return;
    setActiveKeys((prevKeys) => {
      const newKeys = { ...prevKeys, [e.key]: true };
      const newDirection = calculateDirection(newKeys);
      setDirection(newDirection);
      return newKeys;
    });
  };

  const handleKeyUp = (e) => {
    setActiveKeys((prevKeys) => {
      const newKeys = { ...prevKeys, [e.key]: false };
      const newDirection = calculateDirection(newKeys);
      setDirection(newDirection);
      return newKeys;
    });
  };

  AWS.config.update({
    region: AWS_REGION,
    credentials: new AWS.Credentials({
      accessKeyId: AWS_ACCESSKEYID,
      secretAccessKey: AWS_SECRETACCESSKEY,
    }),
  });

  const iotData = new AWS.IotData({
    endpoint: `a12akhqapvce0k-ats.iot.${AWS_REGION}.amazonaws.com`,
  });

  const publishMessage = (dir, action) => {
    const currentSpeed = speedRef.current;
    let payload = {
      linear: {
        x:
          action === "start"
            ? dir.includes("down")
              ? currentSpeed / 10
              : dir.includes("up")
                ? -(currentSpeed / 10)
                : 0.0
            : 0.0,
        y: 0.0,
        z: 0.0,
      },
      angular: {
        x: 0.0,
        y: 0.0,
        z:
          action === "start" && dir.includes("-")
            ? dir.includes("right")
              ? 1.2
              : dir.includes("left")
                ? -1.2
                : 0.0
            : action === "start"
              ? dir === "right"
                ? 1.2
                : dir === "left"
                  ? -1.2
                  : 0.0
              : 0.0,
      },
    };

    const formattedString =
      `{\n` +
      `"linear": {\n` +
      `"x": ${payload.linear.x.toFixed(1)}, \n` +
      `"y": ${payload.linear.y.toFixed(1)}, \n` +
      `"z": ${payload.linear.z.toFixed(1)}\n },\n` +
      `"angular": {\n` +
      `"x": ${payload.angular.x.toFixed(1)}, \n` +
      `"y": ${payload.angular.y.toFixed(1)}, \n` +
      `"z": ${payload.angular.z.toFixed(1)}\n }\n}`;

    const params = {
      topic: AWS_TOPIC,
      payload: formattedString,
      qos: 0,
    };

    iotData.publish(params, (err, data) => {
      if (err) {
        console.error("Error publishing message:", err);
      } else {
        console.log("Message published:", data);
      }
    });
  };

  function handleLogin(data) {
    setIsActivePage(true);
    setUserData(data);
  };

  useEffect(() => {
    const newDirection = calculateDirection();
    setDirection(newDirection);

    if (newDirection) {
      publishMessage(newDirection, "start");
    } else {
      publishMessage("stop");
    }
  }, [activeKeys, speed]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <div className="App">
      <div className={`w-full flex-wrap justify-center items-center bg-[#202020] overflow-auto hide-scrollbar transition-all duration-500 ease-in-out flex ${isActivePage ? 'h-screen opacity-100 visible py-20' : 'opacity-0 h-0 invisible p-0'}`}>
        <div className="xl:w-1/5 lg:w-2/6 w-full">
          <div className="px-5 flex h-full flex-col">
            <div className="flex flex-col bg-[#303030] rounded-lg shadow-lg p-5 h-[700px]">
              <div className="mb-10">
                <img src={robiq} alt="logo" width={48} className="mx-auto my-5" />
                <h1 className="text-md text-white text-center">Welcome to Robiq Dashboard</h1>
              </div>

              <div className="mb-4">
                <h1 className="text-md text-white">User Name: </h1>
                <p className="text-sm text-white">Asad Shahin Moghadam</p>
              </div>

              <div className="mb-4">
                <h1 className="text-md text-white">Email: </h1>
                <p className="text-sm text-white">{ userData.email }</p>
              </div>

              <div className="mb-4">
                <h1 className="text-md text-white">Project: </h1>
                <p className="text-sm text-white">Test</p>
              </div>

              <div className="mb-4">
                <h1 className="text-md text-white">Direction: </h1>
                <p className="text-sm text-white">{direction || "None"}</p>
              </div>

              <div>
                <h1 className="text-md text-white">Speed: </h1>
                <p className="text-sm text-white">{speed / 10}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:w-2/5 lg:w-2/6 w-full">
          <div className="w-full rounded-lg shadow-xl lg:p-0 p-5 h-[430px]">
            <WebRTCStream
              channelName="RobiqGreengrassCore"
              region={AWS_REGION}
              accessKeyId={AWS_ACCESSKEYID}
              secretAccessKey={AWS_SECRETACCESSKEY}
            />
          </div>

          <div className="flex flex-col items-center bg-[#303030] p-5 rounded-lg shadow-lg mt-4 h-[248px]">
            <div className="w-[200px]">
              <input
                type="range"
                min="1"
                max="5"
                value={speed}
                onChange={(e) => setSpeed(Number(e.target.value))}
                className="w-full h-2 bg-[#B87333] rounded-lg appearance-none cursor-pointer outline-none"
              />
            </div>

            <div className="mt-5">
              <div className="flex justify-center mb-3">
                <button
                  className={`p-[20px] h-[55px] w-[55px] rounded-md bg-[#B87333] ${direction.includes("up") ? "bg-[#B87333EE]" : ""
                    }`}
                  onMouseDown={() => handleKeyDown({ key: "ArrowUp" })}
                  onMouseUp={() => handleKeyUp({ key: "ArrowUp" })}
                >
                  <img src={arrow} className="rotate-[270deg]" />
                </button>
              </div>

              <div className="flex justify-center">
                <button
                  className={`p-[20px] h-[55px] w-[55px] rounded-md bg-[#B87333] ${direction.includes("left") ? "bg-[#B87333EE]" : ""
                    }`}
                  onMouseDown={() => handleKeyDown({ key: "ArrowLeft" })}
                  onMouseUp={() => handleKeyUp({ key: "ArrowLeft" })}
                >
                  <img src={arrow} className="rotate-180" />
                </button>

                <button
                  className={`p-[20px] h-[55px] w-[55px] mx-3 rounded-md bg-[#B87333] ${direction.includes("down") ? "bg-[#B87333EE]" : ""
                    }`}
                  onMouseDown={() => handleKeyDown({ key: "ArrowDown" })}
                  onMouseUp={() => handleKeyUp({ key: "ArrowDown" })}
                >
                  <img src={arrow} className="rotate-90" />
                </button>

                <button
                  className={`p-[20px] h-[55px] w-[55px] mb-2 rounded-md bg-[#B87333] ${direction.includes("right") ? "bg-[#B87333EE]" : ""
                    }`}
                  onMouseDown={() => handleKeyDown({ key: "ArrowRight" })}
                  onMouseUp={() => handleKeyUp({ key: "ArrowRight" })}
                >
                  <img src={arrow} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:w-1/4 lg:w-2/6 w-full">
          <BarPieWordCloud />
        </div>
      </div>

      <div className={`w-full transition-all duration-500 ${isActivePage ? 'opacity-0 h-0 hidden' : 'h-full opacity-100 block'}`}>
        <LoginPage onLogin={handleLogin} />
      </div>
    </div>
  );
}

export default App;
