import React, { useEffect, useRef, useState } from "react";
import AWS from "aws-sdk";
import { SignalingClient, Role } from "amazon-kinesis-video-streams-webrtc";

const WebRTCStream = ({
  channelName,
  region,
  accessKeyId,
  secretAccessKey,
}) => {
  const remoteVideoRef = useRef(null);
  const signalingClientRef = useRef(null);
  const peerConnectionRef = useRef(null);
  const remoteStreamRef = useRef(null);

  useEffect(() => {
    const startStreaming = async () => {
      try {
        // Initialize the KinesisVideo client
        const kinesisVideoClient = new AWS.KinesisVideo({
          region,
          credentials: {
            accessKeyId,
            secretAccessKey,
            // sessionToken, // Include this if using temporary credentials
          },
          correctClockSkew: true,
        });

        // Get the channel ARN
        const describeSignalingChannelResponse = await kinesisVideoClient
          .describeSignalingChannel({
            ChannelName: channelName,
          })
          .promise();
        const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;

        // Get signaling channel endpoints
        const getSignalingChannelEndpointResponse = await kinesisVideoClient
          .getSignalingChannelEndpoint({
            ChannelARN: channelARN,
            SingleMasterChannelEndpointConfiguration: {
              Protocols: ["WSS", "HTTPS"],
              Role: "VIEWER",
            },
          })
          .promise();

        const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
          (endpoints, endpoint) => {
            endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
            return endpoints;
          },
          {}
        );

        // Create the Signaling Client
        signalingClientRef.current = new SignalingClient({
          channelARN,
          channelEndpoint: endpointsByProtocol.WSS,
          role: Role.VIEWER,
          region,
          clientId: `viewer-${Date.now()}`, // Unique client ID
          credentials: {
            accessKeyId,
            secretAccessKey,
            // sessionToken, // Include this if using temporary credentials
          },
          systemClockOffset: kinesisVideoClient.config.systemClockOffset,
        });

        const signalingClient = signalingClientRef.current;

        // Create the Kinesis Video Signaling Channels client
        const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
          region,
          endpoint: endpointsByProtocol.HTTPS,
          credentials: {
            accessKeyId,
            secretAccessKey,
            // sessionToken, // Include this if using temporary credentials
          },
          correctClockSkew: true,
        });

        // Get ICE server configuration
        const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
          .getIceServerConfig({
            ChannelARN: channelARN,
          })
          .promise();

        const iceServers = [];
        iceServers.push({
          urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443`,
        });

        getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
          iceServers.push({
            urls: iceServer.Uris,
            username: iceServer.Username,
            credential: iceServer.Password,
          })
        );

        // Create Peer Connection
        peerConnectionRef.current = new RTCPeerConnection({ iceServers });
        const peerConnection = peerConnectionRef.current;

        // Create a remote media stream
        remoteStreamRef.current = new MediaStream();

        // Set the remote video stream
        if (remoteVideoRef.current) {
          remoteVideoRef.current.srcObject = remoteStreamRef.current;
        }

        // Handle ICE Candidates
        peerConnection.addEventListener("icecandidate", ({ candidate }) => {
          if (candidate) {
            signalingClient.sendIceCandidate(candidate);
          }
        });

        // Handle Remote Tracks
        peerConnection.addEventListener("track", (event) => {
          console.log("[VIEWER] Received remote track:", event.track.kind);
          remoteStreamRef.current.addTrack(event.track);
        });

        // Signaling Client Event Handlers
        signalingClient.on("open", async () => {
          console.log("[VIEWER] Connected to signaling service");

          // Create SDP offer
          const offer = await peerConnection.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          });
          await peerConnection.setLocalDescription(offer);

          // Send SDP offer to master
          signalingClient.sendSdpOffer(peerConnection.localDescription);
          console.log("[VIEWER] Sent SDP offer");
        });

        signalingClient.on("sdpAnswer", async (answer) => {
          console.log("[VIEWER] Received SDP answer");
          await peerConnection.setRemoteDescription(answer);
        });

        signalingClient.on("iceCandidate", async (candidate) => {
          console.log("[VIEWER] Received ICE candidate");
          await peerConnection.addIceCandidate(candidate);
        });

        signalingClient.on("close", () => {
          console.log("[VIEWER] Disconnected from signaling channel");
        });

        signalingClient.on("error", (error) => {
          console.error("[VIEWER] Signaling client error: ", error);
        });

        // Connect to the signaling channel
        signalingClient.open();
      } catch (error) {
        console.error("[VIEWER] Error during streaming: ", error);
      }
    };

    startStreaming();

    // Cleanup
    return () => {
      if (signalingClientRef.current) {
        signalingClientRef.current.close();
        signalingClientRef.current = null;
      }
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
        peerConnectionRef.current = null;
      }
      if (remoteStreamRef.current) {
        remoteStreamRef.current.getTracks().forEach((track) => track.stop());
        remoteStreamRef.current = null;
      }
    };
  }, [channelName, region, accessKeyId, secretAccessKey]);

  return (
    <div className="video h-full">
      <video
        className="w-full h-full bg-black rounded-lg"
        ref={remoteVideoRef}
        autoPlay
        playsInline
        muted
      />
    </div>
  );
};

export default WebRTCStream;
